<template>
  <body style="position: relative;">
  <div id="wrap">
		<section>
      <headerLayout v-bind:activeNum="active" v-on:updateActiveNum="updateNum"></headerLayout>
		</section>
    <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>Kornet</h2>
                    <p>국내 최대 용량의 기업전용망을 통한 고품질 인터넷 전용회선 서비스</p>
                </div>
                <div class="location">
                    <ul>
                        <li><a>PRODUCTS</a></li>
                        <li><a>Kornet</a></li>
                        <li><span>서비스소개</span></li>
                    </ul>
                </div>
            </div>
        </section>
        <section class="sub-section svcInfo-kornet bgGray">
            <dl class="innWrap flex sLayout01">
                <dt>
                    <h3 class="sLtitle">서비스 소개</h3>
                    <p>
                        기업, 공공고객을 위한 "기업 전용 시설"을 기반으로 KT 국사에서<br />
                        고객의 사업장까지 광케이블로 연결하는 고품질 인터넷 서비스를<br />
                        제공 합니다.
                    </p>
                    <a @click="$router.push('/goSupCns000')" class="btn-md arrow btn-primary bdrds50 mgr30">컨설팅 신청</a>
                </dt>
                <dd>
                    <div class="imgWrap">
                        <div class="img"></div>
                    </div>
                </dd>
            </dl>
        </section>

        <section class="sub-section DetailInfo">
            <div class="innWrap scrWrap flex">
                <div class="scrNav">
                    <ul>
                        <li>
                            <span class="line" v-bind:class="{active: active === 1}"></span><a @click="moveTab(1)" href="" v-smooth-scroll v-bind:class="{active: active === 1}">상세정보</a>
                        </li>
                        <li>
                            <span class="line" v-bind:class="{active: active === 2}"></span><a @click="moveTab(2)" href="" v-smooth-scroll v-bind:class="{active: active === 2}">요금안내</a>
                        </li>
                        <li>
                            <span class="line" v-bind:class="{active: active === 3}"></span><a @click="moveTab(3)" href="" v-smooth-scroll v-bind:class="{active: active === 3}">이용안내</a>
                        </li>
                    </ul>
                </div>

                <div class="scrCon">
                    <div id="section-01" class="kornet-section-01 Sbdb1px">
                        <h3 class="sCtitle">상세정보</h3>
                        <div class="con01">
                            <h4>1. 기본서비스</h4>

                            <table class="tbl_type03 tbl_purple">
                                <colgroup>
                                    <col style="width: 19%">
                                    <col style="">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>구분</th>
                                        <th>내용</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="agl">Kornet Express</td>
                                        <td class="agl">
                                            기업, 공공 고객을 위한 고품질 인터넷 전용 서비스 입니다.<br />
                                            KT 국사에서 고객사에 인터넷 전용회선을 제공하여 안정적인 Network 환경을 보장합니다.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="agl">Game Express</td>
                                        <td class="agl">
                                            PC방 운영에 최적화된 고속 인터넷 전용 서비스 입니다.<br />
                                            다수의 게임사, 포털사의 서버가 KT IDC에 입주되어 있어 빠른 인터넷 접속환경을 보장합니다.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="agl">KREN (대학교육망),<br />NIS (초중고인터넷),<br />GNS (국가정보통신인터넷)</td>
                                        <td class="agl">
                                            KREN : 대학교육전산망 회원기관을 위한 인터넷 전용 서비스 입니다.<br />
                                            NIS : 초, 중, 고등학교에 제공되는 인터넷 전용 서비스 입니다.<br />
                                            GNS : 국가기관, 공공기관 대상으로 제공되는 인터넷 전용 서비스 입니다.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="agl">빌딩</td>
                                        <td class="agl">
                                            구내인터넷사업장에 입주한 중소규모 오피스에 적합한 실속형 인터넷 입니다.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="mgt20">
                                ※ Metro Ethernet : GiGA급 Ethernet 구성으로 원거리에서도 LAN과 같이 사용 가능한 네트워크 입니다.<br />
                                ※  IPS (Intrusion Prevention System) : 바이러스 웜이나 불법침입, DDOS등의 비정상적인 이상 신호 발생 시 인공지능적으로 적절한 조치를 취하는 보안 시스템 입니다.<br />
                                ※ QoS (Quality of Service) : 통신 서비스 품질, 네트워크상에서 일정 정도 이하의 데이터 지연 시간이나 데이터 손실률 보장을 뜻합니다.
                            </p>
                        </div>
                    </div>

                    <div id="section-02" class="kornet-section-02 Sbdb1px">
                        <h3 class="sCtitle">요금안내</h3>

                        <div class="con01">
                            <h4>1. Kornet Express</h4>
                            <h5 class="pdl30">1) 서비스 구성</h5>
                            <table class="tbl_type03 tbl_Gray">
                                <thead>
                                    <tr>
                                        <th>구분 (제공속도)</th>
                                        <th>일반요금</th>
                                        <th>벤처/CP요금</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1M ~ 30M</td>
                                        <td colspan="2" rowspan="8" class="noList">
                                            요금 견적 별도 문의<br />(1588-0114)<br />※ 약관가는 브로슈어 참조
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>50M</td>
                                    </tr>
                                     <tr>
                                        <td>100M</td>
                                    </tr>
                                    <tr>
                                        <td>200M</td>
                                    </tr>
                                    <tr>
                                        <td>300M</td>
                                    </tr>
                                    <tr>
                                        <td>500M</td>
                                    </tr>
                                    <tr>
                                        <td>1G</td>
                                    </tr>
                                    <tr>
                                        <td>2G ~ 10G</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="mgt20">
                                ※ 벤처/CP사업자 : 중소기업청에서 인정하는 사업자도 포함됩니다.
                            </p>

                            <h5 class="pdl30 mgt40">2) 계약 기간별 할인율</h5>
                            <table class="tbl_type03 tbl_Gray">
                                <colgroup>
                                    <col style="width: 223px">
                                    <col style="">
                                    <col style="">
                                    <col style="">
                                    <col style="">
                                    <col style="">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>구분 (제공속도)</th>
                                        <th colspan="3">3년 이내</th>
                                        <th colspan="3">3년 경과</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>계약 기간</td>
                                        <td>1년</td>
                                        <td>2년</td>
                                        <td>3년</td>
                                        <td>1년</td>
                                        <td>2년</td>
                                        <td>3년</td>
                                    </tr>
                                    <tr>
                                        <td>50M</td>
                                        <td>5% 할인</td>
                                        <td>10% 할인</td>
                                        <td>15% 할인</td>
                                        <td>2% 할인</td>
                                        <td>3% 할인</td>
                                        <td>5% 할인</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="mgt20">
                                ※ 계약기간 추가 시 할인 적용됩니다. (1년 추가 2%, 2년 추가 3%, 3년 추가 5%)
                            </p>

                            <h5 class="pdl30 mgt40">3) 설치비 안내</h5>
                            <table class="tbl_type03 tbl_Gray">
                                <thead>
                                    <tr>
                                        <th>구분 (제공속도)</th>
                                        <th>신규</th>
                                        <th>구외이전</th>
                                        <th>구내이전</th>
                                        <th>비고</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1M ~ 100M</td>
                                        <td class="bgGray"><span class="fcPurple3 fw600">110,000</span>원</td>
                                        <td class="bgGray"><span class="fcPurple3 fw600">110,000</span>원</td>
                                        <td class="bgGray"><span class="fcPurple3 fw600">55,000</span>원</td>
                                        <td class="bgGray">-</td>
                                    </tr>
                                    <tr>
                                        <td>100M 초과</td>
                                        <td class="bgGray"><span class="fcPurple3 fw600">1,067,000</span>원</td>
                                        <td class="bgGray"><span class="fcPurple3 fw600">1,067,000</span>원</td>
                                        <td class="bgGray">-</td>
                                        <td class="bgGray">-</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="mgt20">
                                ※ Kornet Hotline/Express간 상품 전환 시 설치비는 차액 분에 대해서 부과됩니다.<br />
                                <span class="pdl20">단, 전환 전 보다 전환 후의 설치비가 클 경우에만 적용됩니다.</span>
                            </p>

                            <h5 class="pdl30 mgt40">4) 단말장비 임대료</h5>
                            <table class="tbl_type03 tbl_Gray">
                                <thead>
                                    <tr>
                                        <th>구분</th>
                                        <th colspan="2">대여장비</th>
                                        <th>비고</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowspan="2">Kornet Express<br />기업용 단말스위치</td>
                                        <td>가입자단말 S/W(L3)</td>
                                        <td><span class="fcPurple3 fw600">66,000</span>원</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>가입자단말 S/W(GiGA)</td>
                                        <td><span class="fcPurple3 fw600">66,000</span>원</td>
                                        <td>-</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="mgt20">
                                ※ Kornet Hotline/Express간 상품 전환 시 설치비는 차액 분에 대해서 부과됩니다.<br />
                                <span class="pdl20">단, 전환 전 보다 전환 후의 설치비가 클 경우에만 적용됩니다.</span>
                            </p>
                        </div>

                        <div class="con02">
                            <h4>2. Kornet Economy</h4>
                            <div class="dotList indent pdt0">
                                <ul>
                                    <li>중소 기업청이 선정한 중소기업 정보화 지원 대상업체를 위한 저렴한 기업인터넷 서비스입니다.</li>
                                    <li>제공속도 : 1M</li>
                                    <li>이용요금 : 월 605,000원(부가세 포함)</li>
                                </ul>
                            </div>
                        </div>

                        <div class="con03">
                            <h4>3. Game Express</h4>
                            <h5 class="pdl30">1) 서비스 구성</h5>
                            <table class="tbl_type03 tbl_Gray">
                                <colgroup>
                                    <col style="width: 236px">
                                    <col style="">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>구분 (제공속도)</th>
                                        <th>일반요금</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1M ~ 30M</td>
                                        <td rowspan="8" class="noList">
                                            요금 견적 별도 문의<br />(1588-0114)<br />※ 약관가는 브로슈어 참조
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>50M</td>
                                    </tr>
                                     <tr>
                                        <td>100M</td>
                                    </tr>
                                    <tr>
                                        <td>200M</td>
                                    </tr>
                                    <tr>
                                        <td>300M</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="mgt20">
                                ※ GE : Game Express (KT PC방 전용인터넷서비스)
                            </p>

                            <h5 class="pdl30 mgt40">2) 설치비 및 장비 임대료</h5>
                            <div class="dotList indent pdt0">
                                <ul>
                                    <li>설치비(1회성) : 110,000원(부가세 포함)</li>
                                    <li>장비임대료</li>
                                </ul>
                            </div>
                            <table class="tbl_type03 tbl_Gray mgt40">
                                <thead>
                                    <tr>
                                        <th>구분</th>
                                        <th colspan="2">대여장비</th>
                                        <th>비고</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowspan="2">Game Express<br />기업용 단말스위치</td>
                                        <td>가입자단말 S/W(L3)</td>
                                        <td><span class="fcPurple3 fw600">31,900</span>원</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>가입자단말 S/W(GiGA)</td>
                                        <td><span class="fcPurple3 fw600">55,000</span>원</td>
                                        <td>-</td>
                                    </tr>
                                </tbody>
                            </table>

                            <h5 class="pdl30 mgt40">3) 기간별 할인율</h5>
                            <table class="tbl_type03 tbl_Gray mgt40">
                                <thead>
                                    <tr>
                                        <th>계약 연수</th>
                                        <th>1년</th>
                                        <th>2년</th>
                                        <th>3년</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>5% 할인</th>
                                        <th>10% 할인</th>
                                        <th>15% 할인</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowspan="2">Game Express<br />기업용 단말스위치</td>
                                        <td>가입자단말 S/W(L3)</td>
                                        <td><span class="fcPurple3 fw600">31,900</span>원</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>가입자단말 S/W(GiGA)</td>
                                        <td><span class="fcPurple3 fw600">55,000</span>원</td>
                                        <td>-</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="con04">
                            <h4>4. KREN</h4>
                            <div class="dotList indent pdt0">
                                <ul>
                                    <li>서비스 요금 요금안내는 http://kren.ne.kr 또는 080-789-0000으로 문의해 주시기 바랍니다</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div id="section-03" class="kornet-section-03">
                        <h3 class="sCtitle">이용안내</h3>
                        <h4>꼭 알아두세요!</h4>
                        <div class="dotList indent">
                            <ul>
                                <li>서비스 신청에서 개통까지 3~5일정도 소요되며, 광케이블 공사 필요 시에는 1주 정도 소요됩니다.</li>
                                <li>설치환경에 따라 개통일정은 변경될 수 있습니다.</li>
                            </ul>
                        </div>
                        <h4>설치 및 이용 가이드</h4>
                        <div class="dotList indent">
                            <ul>
                                <li>Kornet Express 신청 시 L3 스위치(단말장비)까지 설치해 드리며 KT로부터 저렴한 요금으로 임대 가능합니다.(설치비는 별도 부과됩니다.)</li>
                                <li>구내회선은 고객님께서 직접 구성해 사용하셔야 됩니다.</li>
                            </ul>
                        </div>
                        <h4>신청 및 문의</h4>
                        <div class="dotList indent">
                            <ul>
                                <li>전화상담신청(서비스 문의, 신청 및 해지) : 1588-0114</li>
                                <li>KREN 신청 : 교육전산망운영본부 (http://www.kren.ne.kr)</li>
                                <li>고장(장애)접수 : 1588-0114, 080-789-0099(KREN)</li>
                                <li>1:1 문의하기</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'
import common from '@/assets/js/common.js'

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data () {
    return {        
      active: 1,
				sectionNum: [
					'section-01',
					'section-02',
					'section-03'
				],
    }
  },
  created: function () {

  },
  mounted: function () {
  },
  methods: {
		updateNum(num) {
			this.active = num
		},
    moveTab(num) {
      var location = common.getOffset(document.getElementById(this.sectionNum[num-1])).top - 30
      window.scrollTo({top:location, behavior:'smooth'});
    }
  }
}
</script>